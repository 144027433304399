<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submitData" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Unit Management</h3>
            <hr class="m-t-0 m-b-40" />

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Tipe
                    <small style="color: red">*</small>
                  </label>
                  <div :class="['col-md-9', { invalid: isinvalidTipe }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueTipe"
                      track-by="value"
                      label="text"
                      :options="optionTipe"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeTipe"
                      @close="onTouch"
                      @search-change="tipeFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isinvalidTipe"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    OPD
                  </label>
                  <div :class="['col-md-9', { invalid: isinvalidOpd }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueOpd"
                      track-by="value"
                      label="text"
                      :options="optionOpd"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeOpd"
                      @close="onTouch"
                      @search-change="unitFindOpd"
                    ></Multiselect>
                    <label class="typo__label form__label" v-show="isinvalidOpd"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Induk
                  </label>
                  <div :class="['col-md-9', { invalid: isinvalidInduk }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueInduk"
                      track-by="value"
                      label="text"
                      :options="optionInduk"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeInduk"
                      @close="onTouch"
                      @search-change="unitFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isinvalidInduk"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.kode"
                  name="kode"
                  type="number"
                  label="Kode Penomoran"
                />
              </div>
            </div> -->
            <!-- <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.kode2"
                  name="kode2"
                  type="text"
                  label="Kode"
                />
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nama"
                  name="nama"
                  required
                  type="text"
                  label="Nama"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.alamat"
                  name="alamat"
                  type="text"
                  label="Alamat"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.telepon"
                  name="telepon"
                  type="text"
                  label="Telepon"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.faksimile"
                  name="faksimile"
                  type="text"
                  label="Faksimile"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.website"
                  name="website"
                  type="text"
                  label="Website"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.email"
                  name="email"
                  type="text"
                  label="Email"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.kodepos"
                  name="kodepos"
                  type="text"
                  label="Kodepos"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.akronim"
                  name="akronim"
                  required
                  type="text"
                  label="Akronim"
                />
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >OPD/Pokja <small style="color: red">*</small></label
                  >
                  <div :class="['col-md-9']">
                    <select
                      class="form-control custom-select"
                      v-model="payload.opd"
                      id="selectBox"
                      name="selectBox"
                    >
                      <option value>-- Pilih --</option>
                      <option value="1">OPD / UNIT KERJA</option>
                    </select>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Eselon
                  </label>
                  <div :class="['col-md-9']">
                    <select
                      class="form-control custom-select"
                      v-model="payload.eselon"
                    >
                      <option value>Pilih Eselon</option>
                      <option value="Eselon I">Eselon I</option>
                      <option value="Eselon II">Eselon II</option>
                      <option value="Eselon III">Eselon III</option>
                      <option value="Eselon IV">Eselon IV</option>
                      <option value="NE">Non Eselon</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Penanda Sekretaris / TU</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.is_superior"
                          type="radio"
                          value="1"
                          class="form-check-input"
                          name="optradio"
                        />Ya
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.is_superior"
                          type="radio"
                          value="0"
                          class="form-check-input"
                          name="optradio"
                        />Tidak
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <template v-if="editMode">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="['control-label', {'text-right': !isMobile}, 'col-md-3']"
                    >Deskripsi</label>
                    <div class="col-md-9 col-xs-9">
                      <textarea v-model="payload.deskripsi" class="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <Input
                    v-model="payload.tujuanSurat"
                    name="tujuanSurat"
                    type="text"
                    label="Tujuan Surat"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                      Tujuan Disposisi
                      <small style="color:red">*</small>
                    </label>
                    <div :class="['col-md-9',{ 'invalid': isinvalidTujuan }]">
                      <Multiselect
                        placeholder="Pick at least one"
                        select-label
                        :value="payload.valueTujuan"
                        track-by="id"
                        label="text"
                        :options="optionTujuan"
                        :searchable="true"
                        :allow-empty="false"
                        :hide-selected="false"
                        :max-height="150"
                        :disabled="isDisabled"
                        :block-keys="['Tab', 'Enter']"
                        @input="onChangeTujuan"
                        @close="onTouch"
                      ></Multiselect>
                      <label
                        class="typo__label form__label"
                        v-show="isinvalidTujuan"
                      >Must have at least one value</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input
                    v-model="payload.tembusanKe"
                    name="tembusanKe"
                    type="text"
                    label="Ditembuskan Ke"
                  />
                </div>
              </div>
            </template> -->
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Simpan"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
// import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    // Upload,
    Multiselect,
    Input,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.unit;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isinvalidInduk() {
      return this.isTouchedInduk && this.payload.valueInduk.length === 0;
    },
    isinvalidOpd() {
      return this.isTouchedOpd && this.payload.valueOpd.length === 0;
    },
    isinvalidTujuan() {
      return this.isTouchedTujuan && this.payload.valueTujuan.length === 0;
    },
    isInvalidUnit() {
      return this.isTouched && this.payload.valueUnit.length === 0;
    },
    isinvalidTipe() {
      return this.isTouched && this.payload.valueTipe.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        valueTipe: [],
        valueInduk: [],
        valueOpd: [],
        // kode: "",
        // kode2: "",
        nama: "",
        alamat: "",
        telepon: "",
        faksimile: "",
        website: "",
        email: "",
        kodepos: "",
        akronim: "",
        is_superior: "",
        // opd: "",
        eselon: "",
        jenisUnitKerja: "",
        deskripsi: "",
        tujuanSurat: "",
        valueTujuan: [],
        tembusanKe: "",
      },
      isTouched: false,
      isDisabled: false,
      optionTujuan: [],
      optionInduk: [],
      optionOpd: [],
      optionTipe: [],
      isLoading: false,
    };
  },
  async mounted() {
    this.unitFind("");
    this.unitFindOpd("");
    this.tipeFind("");
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/units/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
              isEdit: true,
            };
            this.$store.commit("unit/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("unit/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        valueInduk: data.parent,
        valueOpd: data.induk,
        valueTujuan: data.user
          ? { id: data.user.id.toString(), text: data.user.name }
          : [],
        // kode: data.id_numbering,
        // kode2: data.kode,
        nama: data.name,
        alamat: data.alamat,
        telepon: data.telepon,
        is_superior: data.is_superior,
        faksimile: data.faksimile,
        website: data.website,
        email: data.email,
        kodepos: data.kodepos,
        akronim: data.akronim,
        // akronim: data.name,
        opd: data.type.toString(),
        deskripsi: data.description === "" ? "-" : data.description,
        tujuanSurat: data.send_to,
        tujuanDisposisi: data.disposition,
        tembusanKe: data.subdistrict_id === null ? "" : data.subdistrict_id,
        type_code: parseInt(data.type_code),
        eselon: data.eselon,
      };
      for (let te in this.optionTipe) {
        if (this.optionTipe[te].value == data.type_code) {
          this.payload.valueTipe = this.optionTipe[te];
        }
      }
      this.findUserbyUnit(data.id);
    },
    findUserbyUnit(id) {
      axios.get(`/findUserbyUnit/${id}`).then((response) => {
        response = response.data;
        response.data.forEach((value) => {
          this.optionTujuan.push({ id: value.id.toString(), text: value.name });
        });
      });
    },
    onTouch() {
      this.isTouched = true;
      this.isTouchedInduk = true;
      this.isTouchedTujuan = true;
    },
    onChangeInduk(value) {
      this.payload.valueInduk = value;
    },
    onChangeOpd(value) {
      this.payload.valueOpd = value;
    },
    onChangeTipe(value) {
      this.payload.valueTipe = value;
    },
    onChangeTujuan(value) {
      this.payload.valueTujuan = value;
    },
    goBack() {
      this.$store.dispatch("unit/onCancel");
    },
    unitFind(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.optionInduk = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    unitFindOpd(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.optionOpd = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    tipeFind(query) {
      this.isLoading = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then((res) => {
          this.optionTipe = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    submitData() {
      const payload = {
        parent_id: parseInt(this.payload.valueInduk.value),
        induk: parseInt(this.payload.valueOpd.value),
        // id_numbering: parseInt(this.payload.kode),
        // kode: this.payload.kode2,
        name: this.payload.nama,
        alamat: this.payload.alamat,
        telepon: this.payload.telepon,
        faksimile: this.payload.faksimile,
        website: this.payload.website,
        email: this.payload.email,
        kodepos: this.payload.kodepos,
        is_superior: this.payload.is_superior,
        // type: "1"
        // type: parseInt(this.payload.opd),
        // type: this.payload.valueTipe.value,
        type: "1",
        eselon: this.payload.eselon,
        akronim: this.payload.akronim,
        // akronim: this.payload.nama,
        scope: 1,
        description: this.payload.deskripsi,
        send_to: parseInt(this.payload.tujuanSurat),
        disposition: parseInt(this.payload.valueTujuan.id),
        carbon_copy: 362,
        // type_code: this.payload.valueTipe.value,
        type_code: "1",
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          const state = {
            loaded: false,
          };
          this.$store.commit("unit/STATE", state);
          if (this.editMode) {
            this.$store
              .dispatch("unit/submitEdit", {
                payload,
                id: this.$route.params.id,
              })
              .then(() => {})
              .catch(() => {
                const state = {
                  loaded: true,
                };
                this.$store.commit("unit/STATE", state);
              });
          } else {
            this.$store
              .dispatch("unit/submitAdd", data)
              .then(() => {})
              .catch(() => {
                const state = {
                  loaded: true,
                };
                this.$store.commit("unit/STATE", state);
              });
          }
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

